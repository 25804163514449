<template>
  <div class="login-flow-tpl" :class="{ club: isClub, compact }">
    <div class="inner-container">
      <RegisteredModal />
      <SignupCompactForm
        v-if="showSignupForm"
        @go-back="showSignupForm = false"
        @confirm-signup="v => ((showSignupForm = !v), $emit('proceed-to-payment'))"
      />
      <ActivationForm
        v-else-if="showActivationForm"
        element-test="login-activation"
        :compact="compact"
        @go-back="showActivationForm = false"
        @confirm-activation="v => ((showActivationForm = !v), (showPasswordForm = true))"
      />
      <PasswordSetForm
        v-else-if="showPasswordForm"
        :compact="compact"
        element-test="login-password-set"
        @go-back="showPasswordForm = false"
        @confirm-password-set="v => ((showPasswordForm = !v), $emit('proceed-to-payment'))"
      />
      <div v-else class="login-content">
        <div class="login-content-wrapper" data-test-id="login-content-wrapper">
          <div class="login-popup">
            <div v-if="confirmSignup">{{ $t('registrationConfirmation.compactTitle') }}</div>
            <div v-else class="login-title-wrapper">
              <button v-if="showPassword" @click="showPassword = false">
                <div class="icon-arrow-left u-bg-night-blue" />
              </button>
              <button v-else-if="compact" @click="$emit('go-back')">
                <div class="icon-arrow-left u-bg-night-blue" />
              </button>
              <h1>{{ isClub ? $t('cartSubscribe.club') : $t('cartSubscribe.youAreCustomer') }}</h1>
            </div>
            <form v-show="!showPassword" @submit.prevent="validateStatus">
              <InputEmailOrPhone
                v-model="emailOrPhone"
                :element-test="isClub ? 'club-phone-or-email' : 'phone-or-email'"
                :required="true"
                class="u-p-b-sm"
                @update:model-value="e => (emailOrPhone = e)"
              />
              <div v-if="isLoading" class="loader" />
              <p v-if="error" class="error" data-test-id="error-phone-login">
                {{ error }}
              </p>
              <MainButton
                data-test-id="login-button"
                :label="$t('actions.next')"
                tag="button"
                icon-position="RIGHT"
                class-variant="full-width"
                :disabled="emailOrPhone === null || emailOrPhone === ''"
              >
                <div class="icon-arrow-right u-bg-white" />
              </MainButton>
            </form>
            <form v-show="showPassword" @submit.prevent="login">
              <InputField
                v-model="password"
                element-test="login-password"
                :input-id="'password'"
                input-type="password"
                :field-title="$t('fields.password')"
                :placeholder-value="$t('fields.password')"
                @update:model-value="e => (password = e)"
              >
                <template #icon>
                  <div class="icon-password u-bg-dark-blue" />
                </template>
              </InputField>
              <div v-if="isLoading" class="loader" />
              <p v-if="error" class="error" data-test-id="error-password-login">
                {{ error }}
              </p>
              <MainButton
                :label="$t('actions.login')"
                data-test-id="login-submit-button"
                tag="button"
                icon-position="RIGHT"
                class-variant="full-width"
                :disabled="password === null || password === ''"
              >
                <div class="icon-arrow-right u-bg-white" />
              </MainButton>
              <p class="login-link-wrapper">
                <a data-test-id="link-forgot-password" @click.prevent="goToResetPassword">
                  {{ $t('login.forgottenPasswordLink') }}
                </a>
              </p>
            </form>
          </div>
          <div v-if="!showPassword && !isClub && !confirmSignup" class="login-popup no-bg">
            <h2>{{ $t('cartSubscribe.haveNoAccount') }}</h2>
            <MainButton
              v-if="compact"
              tag="a"
              :label="$t('actions.register')"
              icon-position="RIGHT"
              class-variant="btn-twilight full-width"
              @click="showSignupForm = true"
            >
              <div class="icon-arrow-right u-bg-white" />
            </MainButton>
            <MainButton
              v-else
              :link="localePath('signup')"
              :label="$t('actions.register')"
              data-test-id="signup-button"
              icon-position="RIGHT"
              class-variant="btn-twilight full-width"
            >
              <div class="icon-arrow-right u-bg-white" />
            </MainButton>
          </div>
        </div>
        <ImageLazy
          v-if="isClub"
          src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/ill-private-club.webp"
          :alt="$t('brandName')"
          :title="$t('brandName')"
          class="login-private-club-illu"
          data-test-id="img-login-private-club-illu"
        />
        <ImageLazy
          v-else
          src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/login-persons.webp"
          :alt="$t('brandName')"
          :title="$t('brandName')"
          class="login-main-illu"
          data-test-id="img-login-main-illu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useClubStore } from '@/stores/club'
import API from '@/mixins/sp-api-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import loginMx from '@/mixins/login-mixin'

import RegisteredModal from '@/components/Modals/RegisteredModal/RegisteredModal'
import InputField from '@/components/InputField/InputField'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputEmailOrPhone from '@/components/InputField/InputEmailOrPhone'
import SignupCompactForm from '@/components/Login/SignupCompactForm/SignupCompactForm'
import ActivationForm from '@/components/Login/ActivationForm/ActivationForm'
import PasswordSetForm from '@/components/Login/PasswordSetForm/PasswordSetForm'

export default {
  name: 'LoginForm',
  components: {
    RegisteredModal,
    SignupCompactForm,
    ActivationForm,
    PasswordSetForm,
    InputField,
    MainButton,
    InputEmailOrPhone,
  },
  mixins: [API, i18nMx, loginMx],
  props: {
    compact: { type: Boolean, default: false },
  },
  emits: ['go-back', 'proceed-to-payment'],
  data() {
    return {
      user: {},
      showPassword: false,
      confirmSignup: false,
      showSignupForm: false,
      showActivationForm: false,
      showPasswordForm: false,
    }
  },
  computed: {
    isClub() {
      const clubStore = useClubStore()
      return clubStore.isClub
    },
  },
  methods: {
    validateStatus() {
      const { $api } = useNuxtApp()
      // First login step with phone number
      if (!this.isLoading && this.emailOrPhone) {
        this.error = null
        // this.$auth.options.redirect = {
        //   login: '/login',
        //   logout: '/',
        //   home: '/',
        // }
        this.spRequest({
          req: $api().user.login(this.emailOrPhone, null),
        }).then(response => {
          if (response.confirmed !== null && response.confirmed !== undefined) {
            // Check if user is already confirmed
            if (response.confirmed) {
              // Ask for credentials
              this.showPassword = true
            } else {
              this.$store.commit('activation/setEmailOrPhone', this.emailOrPhone)
              this.$store.commit('activation/setForgotPassword', false)
              // Go to user activation (SMS validation)
              if (this.compact) {
                this.showActivationForm = true
              } else {
                const localePath = useLocalePath()
                this.$router.push(localePath('login-activation'))
              }
            }
          } else if (response.message === 'already_sent') {
            this.showActivationForm = true
          } else {
            const { country } = useCurrentLocale()
            this.error = this.$te(`backendError.${response.message}`)
              ? this.$t(`backendError.${response.message}`, { phone: country?.phoneNumber })
              : this.$t('backendError.unknown', { phone: country?.phoneNumber })
          }
        })
      }
    },
    login() {
      // Log user with its credentials
      return this.loginMx()
    },
  },
}
</script>
<style lang="scss">
@import '@/styles/partials/tpl/login-flow';
</style>
