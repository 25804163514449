<template>
  <div class="compact-registration">
    <div class="compact-registration-header">
      <img
        src="~/assets/registration/icons/ico-back.svg"
        alt="back"
        class="compact-registration-header-btn"
        @click="$emit('go-back')"
      />
      <h2 class="compact-registration-header-text">
        {{ $t('registration.compact.title') }}
      </h2>
    </div>
    <div class="compact-registration-body">
      <form novalidate @submit.prevent="subscribe">
        <InputField
          id="firstName"
          v-model.trim="user.first_name"
          input-type="text"
          :field-title="$t('cartSubscribe.firstName')"
          :placeholder-value="$t('cartSubscribe.firstName')"
          :clearable="true"
          :input-rules="['required']"
        >
          <template #icon>
            <div class="icon-user u-bg-steel-grey" />
          </template>
        </InputField>
        <InputField
          id="lastName"
          v-model.trim="user.last_name"
          input-type="text"
          :field-title="$t('cartSubscribe.lastName')"
          :placeholder-value="$t('cartSubscribe.lastName')"
          :clearable="true"
          :input-rules="['required']"
        >
          <template #icon>
            <div class="icon-user u-bg-steel-grey" />
          </template>
        </InputField>
        <InputField
          id="company.name"
          v-model.trim="company.name"
          input-type="text"
          :field-title="$t('cartSubscribe.companyName')"
          :placeholder-value="$t('cartSubscribe.companyName')"
          :clearable="true"
        >
          <template #icon>
            <div class="icon-home u-bg-steel-grey" />
          </template>
        </InputField>
        <InputField
          :id="localCompanyCode"
          v-model.trim="company[localCompanyCode.toLowerCase()]"
          input-type="text"
          :field-title="localCompanyCode"
          :placeholder-value="localCompanyCode"
          :clearable="true"
          :input-formatter="tld === 'FR' ? sirenFormatter : lengthFormater"
          :input-rules="['required']"
        >
          <template #icon>
            <div class="icon-home u-bg-steel-grey" />
          </template>
        </InputField>
        <InputField
          id="email"
          v-model.trim="user.email"
          input-type="email"
          :field-title="$t('cartSubscribe.email')"
          :placeholder-value="$t('cartSubscribe.email')"
          :clearable="true"
          :input-rules="['required', 'email']"
        >
          <template #icon>
            <div class="icon-email u-bg-steel-grey" />
          </template>
        </InputField>
        <InputField
          id="password"
          v-model="user.password"
          :input-type="'password'"
          :field-title="$t('cartSubscribe.password')"
          :required="true"
          :placeholder-value="$t('cartSubscribe.password')"
          :input-rules="['required']"
        >
          <template #icon>
            <div class="icon-password u-bg-steel-grey" />
          </template>
        </InputField>
        <InputPhone id="phone" v-model="user.phone" />
        <div class="compact-registration-body-phone-info">
          {{ $t('registration.compact.phoneInfos') }}
        </div>
        <label class="custom-checkbox-wrapper">
          <input id="optin" v-model="optin" type="checkbox" name="optin" required />
          <span
            v-html="
              $t('cart.acceptTerms', {
                button: $t('cartSubscribe.validate'),
                cgvLink: 'cgv',
                cguLink: 'cgu',
              })
            "
          />
        </label>
        <p v-if="error" class="error">
          {{ error }}
        </p>
        <div v-if="isLoading" class="loader" />
        <div class="compact-registration-body-buttons">
          <MainButton
            tag="a"
            :label="$t('actions.cancel')"
            class-variant="btn-grey full-width"
            @click="$emit('go-back')"
          />
          <MainButton
            tag="button"
            :label="$t('cartSubscribe.validate')"
            :disabled="!optin"
            class-variant="full-width"
            icon-position="RIGHT"
          >
            <template #iconRight>
              <div class="icon-check u-bg-white" />
            </template>
          </MainButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import API from '@/mixins/sp-api-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import InputFormatters from '@/helpers/InputFormatters'
import InputField from '@/components/InputField/InputField'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputPhone from '@/components/InputField/InputPhone'

export default {
  name: 'SignupCompactForm',
  components: {
    InputField,
    MainButton,
    InputPhone,
  },
  mixins: [API, i18nMx],
  emits: ['go-back', 'confirm-signup'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isFormInvalid: true,
      user: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        name: null,
        password: null,
      },
      company: {
        name: null,
      },
      optin: false,
    }
  },
  methods: {
    handleFormValidation() {
      for (const item of Object.entries(this.$refs)) {
        this.isFormInvalid = item[1].v$ && item[1].v$.$invalid
        if (this.isFormInvalid) break
      }

      return this.isFormInvalid
    },
    subscribe() {
      const { $api } = useNuxtApp()
      const { tld } = useCurrentLocale()
      this.handleFormValidation()
      if (!this.isLoading) {
        this.error = null
        this.isLoading = true
        const newCompany = {
          ...this.company,
          name: this.company.name || this.user.last_name,
          user: this.user,
        }
        let companyReq = Promise.resolve({})
        if (tld === 'FR') {
          companyReq = $api().company.findWithSiren(this.company.siren)
        }
        this.spRequest({ req: companyReq })
          .then(company => {
            company = {
              ...company,
              ...newCompany,
            }
            if (tld === 'FR') {
              company.country_iso == 'FR'
            }
            return this.spRequest({
              req: $api().company.subscribe(company),
            })
          })
          .then(company => {
            if (company !== null && company !== undefined) {
              return this.spRequest({ req: $api().user.login({ login: this.user.email || this.user.phone }) })
            }
          })
          .catch(e => {
            const { country } = useCurrentLocale()
            this.error = this.$te(`backendError.${e?.message}`)
              ? this.$t(`backendError.${e?.message}`, { phone: country?.phoneNumber })
              : this.$t('backendError.unknown', { phone: country?.phoneNumber })
          })
      }
    },
    phoneFormatter(input) {
      return InputFormatters.phoneFormatter(input)
    },
    sirenFormatter(input) {
      const { country } = useCurrentLocale()
      return InputFormatters.sirenFormatter(input, country?.localCompanyCodeLength)
    },
  },
}
</script>

<style lang="scss">
.compact-registration {
  &-header {
    align-items: center;
    display: flex;
    gap: $spacing-md;

    &-text {
      font-size: pxToRem(20px);
      &-subtitle {
        font-size: pxToRem(15px);
        font-weight: 500;
      }
    }
  }
  &-body {
    p {
      font-weight: 500;
    }

    label.custom-checkbox-wrapper {
      margin: $spacing-md 0;
      span {
        font-weight: 800;
        font-size: pxToRem(12px);
      }
      a {
        text-decoration: none;
      }
    }

    &-phone-info {
      padding-top: $spacing-lg;
      font-size: pxToRem(12px);
      font-weight: 800;
      color: var(--steel-grey);
    }

    &-buttons {
      display: flex;
      gap: $spacing-md;
    }
  }
}
</style>
