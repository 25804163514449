<template>
  <standard-modal
    max-width="748px"
    name="registeredModal"
    class="registered-modal"
    data-test-id="registration-modal"
    element-test="registration-modal"
  >
    <template #content>
      <div data-test-id="registration-modal-content">
        <div class="registered-modal-content">
          <ImageLazy
            src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/avatar-women.webp"
            alt="Application StockPro"
            title="Application StockPro"
            width="160"
          />
          <div>
            <div class="modal-title" data-test-id="modal-title">
              {{ $t('registrationConfirmation.title') }}
            </div>
            <div class="modal-text" data-test-id="modal-text">
              {{ $t('registrationConfirmation.text' + translateKeySuffix) }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #options>
      <div data-test-id="registration-modal-options">
        <div v-if="!pendingValidation" class="flex-wrapper">
          <div class="icon-stockpro-registered" />
          <div class="txt-wrapper">
            <div class="btn-wrapper u-jc-fe">
              <MainButton
                v-if="tld === 'FR'"
                :label="$t('registrationConfirmation.download')"
                :link="localePath('download')"
                class-variant="btn-bg-grey-blue"
              />
              <MainButton
                v-else
                :label="$t('actions.closeButton')"
                tag="a"
                class-variant="btn-bg-grey-blue"
                @click="close"
              />
              <MainButton :label="$t('login.login')" tag="button" data-test-id="signup-modal-login" @click="close()" />
            </div>
          </div>
        </div>
        <div v-else class="flex-wrapper">
          <img src="/logo.svg" class="hide-sm" />
          <div class="txt-wrapper">
            <div class="btn-wrapper u-jc-fe">
              <MainButton
                :label="$t('registrationConfirmation.support')"
                link="tel:0177623880"
                tag="a"
                class-variant="btn-bg-grey-blue"
              />
              <MainButton :label="$t('registrationConfirmation.gotIt')" tag="button" @click="close()" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-modal>
</template>

<script>
import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import i18nMixin from '@/mixins/i18n-mixin'

export default {
  name: 'RegisteredModal',
  components: {
    StandardModal,
    MainButton,
  },
  mixins: [i18nMixin],
  emits: ['modalClose_registeredModal'],
  computed: {
    translateKeySuffix() {
      return this.pendingValidation ? 'Validate' : ''
    },
    pendingValidation() {
      return this.$store.getters['registration/pendingValidation']
    },
  },
  methods: {
    close() {
      this.$bus.emit('modalClose_registeredModal', 'registeredModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.registered-modal {
  &-content {
    display: flex;
    gap: $spacing-md;
    @include mq($mq-sm) {
      flex-direction: column;
      align-items: center;
    }
    .modal-title {
      color: var(--dark-blue);
      font-weight: 800;
      font-size: pxToRem(20px);
      padding-bottom: $spacing-md;
    }
    .modal-text {
      color: var(--steel-grey);
      font-weight: 500;
      font-size: pxToRem(15px);
    }
  }
  .flex-wrapper {
    padding: 10px;
    display: flex;
    align-items: flex-end;

    @include mq($mq-sm) {
      display: block;
      text-align: center;
    }

    // img {
    //   margin: 0 30px 0 0;
    //   width: 160px;
    //   height: auto;

    //   @include mq($mq-sm) {
    //     margin: 0 0 30px 0;
    //   }
    // }

    .icon-stockpro-registered {
      mask-image: url('~/assets/logos/logo-stockpro-color.svg');
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: var(--steel-grey);
      margin: 0 30px 0 0;
      width: 160px;
      height: 45px;

      @include mq($mq-sm) {
        margin: 0 0 30px 0;
      }
    }

    .txt-wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        &:first-child {
          margin-top: 0;
        }
      }

      .btn-wrapper {
        text-align: right;

        @include mq($mq-sm) {
          justify-content: center;
          text-align: center;
          display: flex;
          flex-direction: column-reverse;
        }

        .btn {
          @include mq($mq-sm) {
            margin: $spacing-md 0 0 0;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
